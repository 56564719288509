import React from "react";

export default function Component() {
  const strengths = [
    {
      title: "プロゲーマーが直接指導！",
      description:
        "実績のあるプロゲーマーが経験に基づいた指導をマンツーマンで行います。本気で上達したいあなたにプロが真剣に向き合います。",
      icon: "🌊",
    },
    {
      title: "初めてのコーチングでも楽しく成長できます！",
      description:
        "優しく丁寧なコーチが指導しますので、初めてのコーチングでも質問のしやすい環境です。",
      icon: "🏆",
    },
    {
      title: "現在のゲーム環境(メタ)に合わせた指導が可能！",
      description:
        "現役のプロゲーマーが指導を行うため、アップデートごとに起こる環境の変化に対応した指導を受けられます。",
      icon: "🚴‍♂️",
    },
    {
      title: "自分では気づけない視点を得られます！",
      description:
        "1人ではなかなか気づけない癖や思考、プロから見た視点など、新たな成長のきっかけを提供します。",
      icon: "💻",
    },
  ];

  return (
    <div className="my-40">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-4xl font-bold text-center text-white mb-12">
          OSGコーチングの
          <span className="text-orange-500">４つの強み</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 bg^">
          {strengths.map((strength, index) => (
            <div
              key={index}
              className="bg-[#141414] border-2 border-orange-500 p-6 rounded-lg shadow-md flex flex-col items-center text-center transition-transform duration-300 hover:scale-105"
            >
              <div className="text-5xl mb-4">{strength.icon}</div>
              <h3 className="text-2xl font-bold text-white mb-4">
                {strength.title}
              </h3>
              <p className="text-gray-300">{strength.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
