import React, { useState } from "react";
import coach1 from "../assets/aries.png";
import coach2 from "../assets/nyanmerry.png";
import coachAPEX from "../assets/coach2.jpg";

const CoachList = () => {
  const [selectedCoach, setSelectedCoach] = useState(null);

  const toggleModal = (coach) => {
    if (selectedCoach) {
      setSelectedCoach(null);
    } else {
      setSelectedCoach(coach);
    }
  };

  const coaches = [
    {
      name: "ShizuAries",
      description:
        "プロ選手達にも認められる個人能力の秘訣を限られた時間内で最大限お伝えします！",
      image: coach1,
      title: "APEX",
    },
    {
      name: "NyanMerry",
      title: "APEX",
      description:
        "優しく丁寧に教えるのがモットーです。受講者の気持ちを汲み取って教えていくのが得意です！",
      image: coach2,
    },
  ];

  return (
    <div className="container mx-auto p-4 ">
      <h2 className="text-2xl md:text-4xl font-bold text-center text-white mb-12">
        OSGコーチングには、多くの
        <span className="text-orange-500">現役プロゲーマー</span>
        がコーチとして在籍しています。
      </h2>
      <div className="flex flex-wrap justify-center gap-4">
        {coaches.map((coach, index) => (
          <div
            key={index}
            className="coach-card bg-[#141414] border border-orange-500 p-4 rounded-lg shadow-md flex flex-col items-center  w-full max-w-xs"
          >
            <img
              src={coach.image}
              alt={coach.name}
              className="w-40 h-40 rounded-full mb-3"
            />
            <h3 className="text-2xl font-bold text-white mb-2">{coach.name}</h3>
            <div>
              <h3 className="text-lg font-bold text-white mb-2">
                対応タイトル: {coach.title}
              </h3>
              <p className="text-white text-sm">{coach.description}</p>
            </div>
            <div className="my-4 mt-3 md:mt-auto ">
              <button
                onClick={() => toggleModal(coach)}
                className="group relative inline-flex h-10  items-center justify-center overflow-hidden rounded-full border border-neutral-200 bg-white font-medium"
              >
                <div className="inline-flex h-12 translate-y-0 items-center justify-center px-6 text-neutral-950 transition duration-500 group-hover:-translate-y-[150%]">
                  実績はこちら
                </div>
                <div className="absolute inline-flex h-12 w-full translate-y-[100%] items-center justify-center text-neutral-50 transition duration-500 group-hover:translate-y-0">
                  <span className="absolute h-full w-full translate-y-full skew-y-12 scale-y-0 bg-orange-500 transition duration-500 group-hover:translate-y-0 group-hover:scale-150"></span>
                  <span className="z-10">PLAYERDETAIL</span>
                </div>
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* モーダルのUI */}
      {selectedCoach && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative">
            {/* 閉じるボタン */}
            <button
              onClick={() => toggleModal(null)}
              className="absolute top-2 right-2 text-white text-3xl font-bold focus:outline-none"
              aria-label="閉じる"
            >
              &times;
            </button>
            {/* モーダル内の画像を小さく表示 */}
            <img
              src={coachAPEX}
              alt="Coach Details"
              className="w-[1300px] max-w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CoachList;
