import React from "react";

export default function Component() {
  return (
    <div className="bg-black mt-32 mb-6">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-white mb-12">
          料金プラン
        </h2>
        <div className="flex flex-wrap gap-6">
          {/* Single Session */}
          <div className="plan-card bg-[#141414] border-2 border-orange-500 rounded-lg shadow-md p-6 flex-1 min-w-[300px]">
            <h3 className="text-2xl font-bold mb-4 text-white">
              シングルセッション
            </h3>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>料金:</strong> 5,500円
            </p>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>時間:</strong> 60分
            </p>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>内容:</strong> プロ選手が1対1でのトレーニングを提供。
            </p>
            <p className="text-white py-4">
              <strong>アピールポイント:</strong>{" "}
              未経験者から高ランク帯まで幅広く、それぞれのレベルに合わせて丁寧にコーチングします!
            </p>
          </div>

          {/* Growth Session */}
          <div className="plan-card bg-[#141414] border-2 border-orange-500 rounded-lg shadow-md p-6 flex-1 min-w-[300px]">
            <h3 className="text-2xl font-bold mb-4 text-white">
              グロースセッション
            </h3>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>料金:</strong> 20,000円〜
            </p>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>時間:</strong> 60分/回、4回セット
            </p>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>内容:</strong>{" "}
              長期的にスキル向上を目指すプレイヤー向けに、定期的なセッションとフィードバックを組み合わせたプラン。
            </p>
            <p className="text-white py-4">
              <strong>アピールポイント:</strong>{" "}
              目標のランクを定め、現状との差から具体的なスキル向上のロードマップを提案させていだだきます!
            </p>
          </div>

          {/* Group Session */}
          <div className="plan-card bg-[#141414] border-2 border-orange-500 rounded-lg shadow-md p-6 flex-1 min-w-[300px]">
            <h3 className="text-2xl font-bold mb-4 text-white">
              グループセッション
            </h3>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>料金:</strong> 3,300円/人
            </p>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>時間:</strong> 60分
            </p>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>内容:</strong>{" "}
              3人チームでのムーブ、ファイトや声のかけ方、チームとしての動きなどについて指導します。
            </p>
            <p className="text-white py-4">
              <strong>アピールポイント:</strong>
              チームで一緒に強くなりたい方におすすめです！
            </p>
          </div>

          {/* Replay Analysis */}
          <div className="plan-card bg-[#141414] border-2 border-orange-500 rounded-lg shadow-md p-6 flex-1 min-w-[300px]">
            <h3 className="text-2xl font-bold mb-4 text-white">リプレイ分析</h3>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>料金:</strong> 3,300円
            </p>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>時間:</strong> ※原則一試合分の時間の料金となります。
            </p>
            <p className="text-white border-b border-orange-500 py-4">
              <strong>内容:</strong>{" "}
              プレイ動画の分析を行い、ムーブ、ファイト内容、癖などの改善ポイントや気づきを得られる文面でのレポートをお送りします。
            </p>
          </div>

          {/* Options */}
        </div>
      </div>
    </div>
  );
}
