import React, { useEffect } from "react";
import Coach from "../components/Coach";
import CoachAbout from "../components/CoachAbout";
import OsgCoach from "../components/OsgCoach";
import CoachList from "../components/CoachList";
import Price from "../components/Price";
import CoachForm from "../components/CoachForm";
import CoachQuestion from "../components/CoachQuestion";
const Corching = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Coach />
      <CoachAbout />
      <OsgCoach />
      <CoachList />
      <Price />
      <CoachForm />
      <CoachQuestion />
    </div>
  );
};

export default Corching;
