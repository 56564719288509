import React, { useState } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { FaPlusCircle } from "react-icons/fa";
import { SlMinus } from "react-icons/sl";
const CoachQuestion = () => {
  const [isOpenIT, setIsOpenIT] = useState(false);
  const [isOpenAdmin, setIsOpenAdmin] = useState(false);
  const [isOpenHR, setIsOpenHR] = useState(false);
  const [isOpenPR, setIsOpenPR] = useState(false);
  const [isOpenSales, setIsOpenSales] = useState(false);
  const [isOpenFinance, setIsOpenFinance] = useState(false);
  return (
    <div className=" container mx-auto my-12 md:my-24 flex flex-col gap-3">
      <h2 className="text-4xl font-bold text-center text-white mb-12">
        よくあるご質問
      </h2>
      <div>
        <button
          className="flex justify-between w-full bg-orange-400 text-white p-3 rounded-lg hover:bg-red-600 transition-all duration-300 ease-in-out"
          onClick={() => setIsOpenIT(!isOpenIT)}
        >
          <div className="text-2xl md:text-4xl flex gap-3 items-center">
            <FaRegQuestionCircle />
            <span className="text-xl md:text-3xl  font-bold">
              年齢制限はありますか？
            </span>
          </div>
          <span className="text-2xl md:text-4xl">
            {isOpenIT ? <SlMinus /> : <FaPlusCircle />}
          </span>
        </button>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out bg-[#141414] text-white text-base 
        ${isOpenIT ? "max-h-full opacity-100" : "max-h-0 opacity-0"}`}
        >
          <div className="p-3 flex flex-col gap-3 font-titleFont text-left">
            <p className=" text-base md:text-xl font-bold">
              Discordを用いてサービスを行うため、12歳以下は受講ができません。
            </p>
          </div>
        </div>
      </div>
      <div>
        <button
          className="flex justify-between w-full bg-orange-500 text-white p-3 rounded-lg hover:bg-red-600 transition-all duration-300 ease-in-out"
          onClick={() => setIsOpenAdmin(!isOpenAdmin)}
        >
          <div className="text-2xl md:text-4xl flex gap-3 items-center">
            <FaRegQuestionCircle />
            <span className="text-xl md:text-3xl  font-bold">
              支払い方法は何がありますか？
            </span>
          </div>
          <span className="text-2xl md:text-4xl">
            {isOpenAdmin ? <SlMinus /> : <FaPlusCircle />}
          </span>
        </button>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out bg-[#141414] text-white text-base 
        ${isOpenAdmin ? "max-h-full opacity-100" : "max-h-0 opacity-0"}`}
        >
          <div className="p-3 flex flex-col gap-3 font-titleFont text-left">
            <p className=" text-base md:text-xl font-bold">
              クレジットカード決済、銀行振込が可能です。
            </p>
          </div>
        </div>
      </div>
      <div>
        <button
          className="flex justify-between w-full bg-orange-500 text-white p-3 rounded-lg hover:bg-red-600 transition-all duration-300 ease-in-out"
          onClick={() => setIsOpenHR(!isOpenHR)}
        >
          <div className="text-2xl md:text-4xl flex gap-3 items-center">
            <FaRegQuestionCircle />
            <span className="text-xl md:text-3xl  font-bold">
              動画提出は必須ですか？
            </span>
          </div>
          <span className="text-2xl md:text-4xl">
            {isOpenHR ? <SlMinus /> : <FaPlusCircle />}
          </span>
        </button>
        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out bg-[#141414] text-white text-base 
        ${isOpenHR ? "max-h-full opacity-100" : "max-h-0 opacity-0"}`}
        >
          <div className="p-3 flex flex-col gap-3 font-titleFont text-left">
            <p className=" text-base md:text-xl font-bold">
              動画提出は無しでもコーチングは可能です！
              頂いたほうがより良いコーチングのご提供が可能ですので、動画提出いただくのをおすすめしております！
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachQuestion;
