import React, { useEffect, useState } from "react";
import { motion, useMotionValue, useMotionValueEvent } from "framer-motion";

// 画像のインポート
import apeximg from "../assets/coach2.jpg";
import osgimg from "../assets/coachAPEX.png";

const imgs = [osgimg, apeximg];

const DRAG_BUFFER = 50;
const SPRING_OPTIONS = {
  type: "spring",
  mass: 3,
  stiffness: 400,
  damping: 50,
};
const ONE_SECOND = 2500;
const AUTO_DELAY = ONE_SECOND * 2;

export default function Component() {
  const [imgIndex, setImgIndex] = useState(0);
  const [dragging, setDragging] = useState(false);

  const dragX = useMotionValue(0);
  const dragXprogress = useMotionValue(0);

  useMotionValueEvent(dragX, "change", (latest) => {
    if (typeof latest === "number" && dragging) {
      dragXprogress.set(latest);
    } else {
      dragXprogress.set(0);
    }
  });

  useEffect(() => {
    const intervalRef = setInterval(() => {
      const x = dragXprogress.get();

      if (x === 0) {
        setImgIndex((pv) => {
          if (pv === imgs.length - 1) {
            return 0;
          }
          return pv + 1;
        });
      }
    }, AUTO_DELAY);

    return () => clearInterval(intervalRef);
  }, []);

  const onDragStart = () => setDragging(true);
  const onDragEnd = () => {
    setDragging(false);

    const x = dragX.get();

    if (x <= -DRAG_BUFFER && imgIndex < imgs.length - 1) {
      setImgIndex((pv) => pv + 1);
    } else if (x >= DRAG_BUFFER && imgIndex > 0) {
      setImgIndex((pv) => pv - 1);
    }
  };

  return (
    <div className="relative overflow-hidden w-full h-[50vh] md:h-screen flex flex-col justify-center bg-gradient-to-b from-black to-[#141414]">
      <div className="md:mt-24 w-full h-[25vh] md:h-screen relative">
        <motion.div
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          animate={{
            translateX: `-${imgIndex * 100}%`,
          }}
          style={{ x: dragX }}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          transition={SPRING_OPTIONS}
          className="flex items-center cursor-grab active:cursor-grabbing w-full h-full absolute"
        >
          {imgs.map((imgSrc, idx) => (
            <motion.div
              key={idx}
              style={{
                backgroundImage: `url(${imgSrc})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover", // 画像をコンテナにフィットさせる
                backgroundPosition: "center", // 画像の位置を中央に
              }}
              className="w-full h-full shrink-0"
              transition={SPRING_OPTIONS}
            />
          ))}
        </motion.div>
      </div>

      <div className="w-full md:absolute md:bottom-4 md:left-0 md:right-0 md:z-10">
        <Dots imgIndex={imgIndex} setImgIndex={setImgIndex} />
      </div>
    </div>
  );
}

function Dots({ imgIndex, setImgIndex }) {
  return (
    <div className="flex justify-center gap-2">
      {imgs.map((_, idx) => (
        <button
          key={idx}
          onClick={() => setImgIndex(idx)}
          className={`h-2 w-2 md:h-3 md:w-3 rounded-full transition-colors ${
            imgIndex === idx ? "bg-orange-500" : "bg-white"
          }`}
        />
      ))}
    </div>
  );
}
