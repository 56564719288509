import React from "react";
import corch from "../assets/corch.png";
const CorchAbout = () => {
  return (
    <div className="about-page container mx-auto p-8 flex flex-wrap gap-8 md:my-20">
      {/* Left Section - Text */}
      <div className="text-section flex-1 min-w-[300px]">
        <h2 className="text-3xl font-bold text-white mb-6">about</h2>
        <h2 className="text-2xl md:text-3xl font-bold text-orange-500 ">
          現役のプロゲーマーから直接指導を受け、
        </h2>
        <h2 className="text-2xl md:text-3xl font-bold text-orange-500 mb-6">
          ランク向上、スキル向上、プロプレイヤーになるための挑戦などをサポートします。
        </h2>
        <p className="text-white mb-4">
          このコーチングのサービスは、チームに所属する選手からの声がきっかけで始まりました。
        </p>
        <p className="text-white mb-4">
          私たち運営メンバーは選手の収入や第2のキャリアを大事にしており、その重要な一つのピースになると考えています。
        </p>
        <p className="text-white mb-4">
          選手がコーチとしての経験や実績を積みながら、日本の競技シーンのレベルを底上げできたらいいなと思います。
          もちろん、選手はコーチングを行うにあたって本気で取り組んでいます。
        </p>
        <p className="text-white mb-4">
          お客様一人ひとりに事前にコーチングしたい内容をしっかり考えてお伝えできるよう準備をしています。
          きっと成長に繋がり、良い刺激になるかと思いますので、ぜひ体験していただけますと幸いです。
        </p>
      </div>

      {/* Right Section - Image */}
      <div className="image-section flex-1 min-w-[300px]">
        <img
          src={corch}
          alt="About Us"
          className="w-full h-auto rounded-lg shadow-md"
        />
      </div>
    </div>
  );
};

export default CorchAbout;
