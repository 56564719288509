import React, { useState } from "react";

const ApplyButton = () => {
  const [isHovered, setIsHovered] = useState(false);

  const handleApply = () => {
    console.log("応募ボタンがクリックされました");
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        padding: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <a
        href="https://discord.gg/8jTjvtqTEn"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        <button
          onClick={handleApply}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          style={{
            background: "linear-gradient(to right, #f97316, #ea580c)",
            color: "white",
            fontWeight: "bold",
            fontSize: "1.5rem",
            padding: "1rem 2rem",
            borderRadius: "1rem",
            border: "none",
            borderBottom: "0.5rem solid #c2410c",
            transition: "all 0.3s ease-in-out",
            transform: isHovered ? "scale(1.1) rotate(1deg)" : "scale(1)",
            boxShadow: isHovered
              ? "0 0 40px rgba(255,165,0,1)"
              : "0 0 20px rgba(255,165,0,0.7)",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              transition: "transform 0.3s ease-in-out",
              transform: isHovered ? "rotate(12deg) scale(1.1)" : "none",
            }}
          >
            <path
              d="M21 6H3C1.9 6 1 6.9 1 8V16C1 17.1 1.9 18 3 18H21C22.1 18 23 17.1 23 16V8C23 6.9 22.1 6 21 6ZM21 16H3V8H21V16ZM6 15H8V13H10V11H8V9H6V11H4V13H6V15Z"
              fill="currentColor"
            />
          </svg>
          <span
            style={{
              transition: "transform 0.3s ease-in-out",
              transform: isHovered ? "translateX(0.5rem)" : "none",
            }}
          >
            応募する
          </span>
          <span
            style={{
              position: "absolute",
              inset: 0,
              background:
                "linear-gradient(to right, transparent, white, transparent)",
              opacity: isHovered ? 0.3 : 0,
              transform: "skew(-12deg)",
              transition: "all 1s ease-in-out",
              left: isHovered ? "100%" : "-100%",
            }}
          ></span>
        </button>
      </a>
    </div>
  );
};

export default ApplyButton;
